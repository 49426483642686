import React, { useCallback, useMemo, useEffect, useRef, memo } from "react";
// "https://smartdevpreneur.com/wave-box-with-svg-sine-wave-animation/"
// https://codesandbox.io/s/wave-box-svg-sine-wave-animation-k8ulc?file=/src/App.js:3111-3680
import { Box } from "@mui/system";
import { motion, useAnimation, useInView } from "framer-motion";
import { getWavedPathD, getWavedSvgPathDsBetween2Points } from "../../utils/getWave";


const styleSxWrap = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 500,
    "& .wrapWave": {
        position: "relative",
        "& .wavedPath": {
            stroke: "teal",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            fill: "none",
        },

        "& .dotAbs": {
            width: 50,
            height: 50,
            borderRadius: "50%",
            position: "absolute",
            background: "#ffffff",
            mx: "auto",
            top: 0,
        }
    }
}


/** WavePathBox
 * animated 2 balls on waved route
 * on hover start stop
 * @param  {} { numberOfSides=4
 * @param  {} oneWave=2*Math.PI
 * @param  {} frequencyPerSide=5
 * @param  {} amplitude=10
 * @param  {} paddingOffset=110
 * @param  whatSides = { isTop: true, isLeft: true, isBottom: true, isRight: true },
 * @param  sidesRatio100Perc = { partTop: 0.25, partLeft: 0.25, partBottom: 0.25, partRight: 0.25 }
*           }
 */
const WavePathBox = (props) => {

    const {
        numberOfSides = 4,
        oneWave = 2 * Math.PI,
        frequencyPerSide = 3,
        amplitude = 5,
        paddingOffset = 110,
        whatSides = { isTop: true, isLeft: true, isBottom: true, isRight: true },
        sidesRatio100Perc = { partTop: 0.25, partLeft: 0.25, partBottom: 0.25, partRight: 0.25 }
    } = props

    // const totalPoints = Math.floor(
    //     oneWave * frequencyPerSide * amplitude * numberOfSides
    // );

    // const pathFunction = useCallback(
    //     (point) => {
    //         return Math.sin(point / 10) * amplitude;
    //         // Math.sin(x*frequency)*amplitude;
    //         //Complete wave in 2*Math.PI (i.e. 6.28318)
    //     },
    //     [ amplitude ]
    // );

    // const calculateTop = useCallback(
    //     (point) => {
    //         const xyPoint = {
    //             x: paddingOffset + point,
    //             y: paddingOffset - pathFunction(point)
    //         };
    //         return [ "L", xyPoint.x, xyPoint.y ].join(" ");
    //     },
    //     [ paddingOffset, pathFunction ]
    // );

    // const calculateRight = useCallback(
    //     (point) => {
    //         const xyPoint = {
    //             x: paddingOffset + totalPoints * 0.25 + pathFunction(point),
    //             y: paddingOffset + (point - totalPoints * 0.25)
    //         };
    //         return [ "L", xyPoint.x, xyPoint.y ].join(" ");
    //     },
    //     [ paddingOffset, pathFunction, totalPoints ]
    // );

    // const calculateBottom = useCallback(
    //     (point) => {
    //         const xyPoint = {
    //             x: paddingOffset + totalPoints * 0.25 - (point - totalPoints / 2),
    //             y: paddingOffset + totalPoints * 0.25 + pathFunction(point)
    //         };
    //         return [ "L", xyPoint.x, xyPoint.y ].join(" ");
    //     },
    //     [ paddingOffset, pathFunction, totalPoints ]
    // );

    // const calculateLeft = useCallback(
    //     (point) => {
    //         const xyPoint = {
    //             x: -pathFunction(point) + paddingOffset,
    //             y: totalPoints * 0.25 - (point - totalPoints * 0.75) + paddingOffset
    //         };
    //         return [ "L", xyPoint.x, xyPoint.y ].join(" ");
    //     },
    //     [ paddingOffset, pathFunction, totalPoints ]
    // );

    // const getWavedSvgPathD = useCallback(() => {
    //     const svgPathD = [];
    //     svgPathD.push([ "M", paddingOffset, paddingOffset ].join(" "));
    //     for (let point = 1; point < totalPoints; point++) {
    //         if (point < totalPoints * 0.25) {
    //             svgPathD.push(calculateTop(point));
    //         } else if (totalPoints * 0.25 <= point && point < totalPoints * 0.5) {
    //             svgPathD.push(calculateRight(point));
    //         } else if (totalPoints * 0.5 <= point && point < totalPoints * 0.75) {
    //             svgPathD.push(calculateBottom(point));
    //         } else {
    //             svgPathD.push(calculateLeft(point));
    //         }
    //         point += 1;
    //     }
    //     svgPathD.push([ "L", paddingOffset, paddingOffset ].join(" "));
    //     return `${svgPathD.join(" ")}`

    // }, [
    //     paddingOffset,
    //     totalPoints,
    //     calculateTop,
    //     calculateRight,
    //     calculateBottom,
    //     calculateLeft
    // ]);

    const refWrap = useRef(null)
    const isInView = useInView(refWrap)

    const controlsPath = useAnimation()
    const controlsBox = useAnimation()


    const dPath = getWavedPathD({
        oneWave,
        frequencyPerSide,
        amplitude,
        numberOfSides,
        whatSides,
        sidesRatio100Perc,
        paddingOffset
    })

    const dPath2 = getWavedSvgPathDsBetween2Points()
    const wavedMotionPath = useMemo(() => {
        return (
            <motion.path
                initial={false}
                animate={controlsPath}
                id="wave"
                className="wavedPath"
                d={dPath.toString()}
                strokeDasharray="0 1"
                key="wpb-path1"
            />
        );
    }, [ dPath, controlsPath ]);

    const wavedMotionPathBetween2Points = useMemo(() => {
        return (
            <motion.path
                initial={false}
                animate={controlsPath}
                id="wave2p"
                className="wavedPath"
                d={dPath2.join(" ")}
                strokeDasharray="0 1"
                key="wpb-path2"
            />
        );
    }, [ dPath2, controlsPath ]);


    const baseTransition = useMemo(() => {
        return {
            duration: 2,
            repeat: 4,
            repeatType: "reverse",
            ease: "easeInOut",
        }
    }, [])


    const runOnetimeAnimationCallb = useCallback(() => {
        controlsPath.start({
            pathLength: [ 0, 1 ],
            opacity: [ 0, 1 ],
            transition: {
                pathLength: { ...baseTransition },
                opacity: {
                    duration: 0.01,
                    ease: "easeInOut",
                },
            },
        })
        controlsBox.start({
            offsetDistance: [ "0%", "100%" ],
            scale: [ 1.5, 0.5 ],
            skewX: [ 0, 30 ],
            backgroundColor: [ "#ffffff", "#c58003" ],
            borderRadius: [ "50%", "0%" ],
            transition: { ...baseTransition },
        })
    }, [ controlsPath, controlsBox, baseTransition ])

    useEffect(() => {
        if (window) {
            if (!isInView) return
            const timerRet = window.setTimeout(() => runOnetimeAnimationCallb()
                , 1000)
            return () => {
                clearTimeout(timerRet)
            }
        }
    }, [ runOnetimeAnimationCallb, isInView ])

    // make sure to catch any error
    // timerRet = runOnetimeAnimation().catch(console.error);
    //   const asyncDelay = ms => new Promise(resolve => setTimeout(resolve, ms));
    // await asyncDelay(5000)

    return (
        <Box component="div"
            ref={refWrap}
            sx={styleSxWrap}
        >
            <motion.div
                className="wrapWave"
                onHoverStart={(e, info) => {
                    runOnetimeAnimationCallb()
                }}
                onHoverEnd={e => {
                    controlsBox.stop()
                    controlsPath.stop()
                }}
                onTapStart={(e, info) => {
                    runOnetimeAnimationCallb()
                }}
                onTapCancel={e => {
                    controlsBox.stop()
                    controlsPath.stop()
                }}
            // onTap={(e, info) => handleTap(e, info, { position })}
            // onTap={async (e) => await handleOnItemTapAsync({ e, iItem: index, controlsBLItem, stopItemUseEffectAnimation })}
            >

                <svg
                    width={500} // {Math.ceil(totalPoints / 4 + paddingOffset * 2)} //each side needs length of 1/4 totalPoints + 2 * offset
                    height={500} // {Math.ceil(totalPoints / 4 + paddingOffset * 2)}
                >
                    {wavedMotionPath}
                    {wavedMotionPathBetween2Points}
                </svg>
                <motion.div
                    className="dotAbs"
                    initial={false}
                    animate={controlsBox}
                    transition={baseTransition}

                    style={{
                        offsetDistance: "0%",
                        scale: 1.5,
                        offsetPath: `path("${dPath}")`,
                    }}
                    key="wpb-dotAbs-path1"
                />
                <motion.div
                    className="dotAbs"
                    initial={false}
                    animate={controlsBox}
                    transition={baseTransition}
                    style={{
                        offsetDistance: "0%",
                        scale: 1.5,
                        offsetPath: `path("${dPath2.join(" ")}")`,
                    }}
                    key="wpb-dotAbs-path2"
                />
                <br />
            </motion.div>
        </Box>
    )

}

export default memo(WavePathBox)